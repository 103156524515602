import { FactorStrategy } from "../../../api/schema";
import { useLongShortCumulativeQuery } from "../../../hooks/useFactors"
import ReactECharts from 'echarts-for-react';
import { toFixed } from "../../../utils/utils";

const LongShortCumulativeChart = ({
    factor
}: {
    factor: FactorStrategy
}) => {
    const longShortCumulativeQuery = useLongShortCumulativeQuery(factor.userId, factor.PK, factor.Factor);

    if (longShortCumulativeQuery.data) {
        const factorSeries: any = longShortCumulativeQuery.data.map((point: any) => {
            return [point.Date, point.Factor * 100] 
        });
        const universeSeries: any = longShortCumulativeQuery.data.map((point: any) => {
            return [point.Date, point.Universe * 100] 
        });



        const options = {
            xAxis: {
                type: 'time'
              },
            yAxis: {
                type: 'value',
                name: 'Performance',
                nameLocation: 'middle',
                nameGap: 50,
                axisLabel: {
                    formatter: "{value}%",
                    margin: 5
                }
            },
            series:         [{
                data: factorSeries,
                type: 'line',
                name: "Factor",
                showSymbol: false,
                hoverAnimation: false,
            }, {
                data: universeSeries,
                type: 'line',
                name: "Universe",
                showSymbol: false,
                hoverAnimation: false,
            
            }],
            tooltip: {
                trigger: 'axis',
                formatter: function (param: any) {
                    return  (
                        `Date: ${new Date((param[0].value[0])).toLocaleDateString()} <br/>
                        ${param.map((p: any) => `<div style="display:flex; justify-content:space-between"><span style=margin-right:10px>${p.marker} ${p.seriesName}</span> <span>${p.value[1] < 0 ? "" : "+" }${toFixed(p.value[1])}%</span></div>`).join("")}
                        `
                    );
                }

            },
          };
        return (
            <div>
                <ReactECharts option={options} />
            </div>
        )
    }

    return (
        <div>
            Loading...
        </div>
    )
}

export default LongShortCumulativeChart