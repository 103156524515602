import { createBrowserRouter } from "react-router-dom"
import Root from "../controls/layout/Root";
import Menu from "../controls/layout/menu";
import FullScreenContent from "../controls/layout/FullScreenContent";
import { PortfolioDetail } from "../controls/pages/portfolioDetail";
import ProtectedRoute from "../controls/common/ProtectedRoute";
import { AuthProvider } from "../hooks/useAuth";
import ModelConfiguration from "../controls/pages/modelConfiguration";
import Dashboard from "../controls/pages/dashboard";
import Strategy from "../controls/pages/strategy";
import { BusyProvider } from "../hooks/useBusy";
import { MessageProvider } from "../hooks/useMessages";
import { WebSocketProvider } from "../context/wsContext";

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <AuthProvider>
                <ProtectedRoute>
                    <MessageProvider>
                        <BusyProvider>
                            <Root>
                                <WebSocketProvider>
                                    <Menu />
                                    <FullScreenContent/>
                                </WebSocketProvider>
                            </Root>
                        </BusyProvider>
                    </MessageProvider>
                </ProtectedRoute>
            </AuthProvider>),
        children: [
            {
                path: "",
                element: <Dashboard />
            },
            {
                path: ":modelId",
                element: <PortfolioDetail/>
            },
            {
                path: "model",
                element: <ModelConfiguration/>
            },
            {
                path: "model/:modelId",
                element: <ModelConfiguration/>
            },
            {
                path: "strategy/:modelId",
                element: <Strategy/>
            }
        ]
    }
    
]);

export default router;