import { Col, Row, Table } from "react-bootstrap";
import { MdExpand } from "react-icons/md";
import styles from './Strategy.module.scss';
import { PortfolioConstructionType } from "../../../api/schema";
import { isLowestScore } from "../../../utils/utils";

const RankingTable = ({
    portfolioType,
    slider,
    assetCount,
    rankingRule
}: {
    portfolioType: PortfolioConstructionType;
    slider: number;
    assetCount: number;
    rankingRule?: "highest" | "lowest";
}) => {
    const isAscending = !isLowestScore(rankingRule === "lowest", portfolioType);

  return (
    <>
        {portfolioType !== "ShortOnly"  &&
            <Row>
                <Col sm={9}>
                    <Table>
                        <thead>
                            <tr>
                                
                                <th style={{
                                    width: "4rem"
                                    }}>Rank</th>
                                <th style={{
                                    width: "4rem"
                                    }}>Score</th>
                                <th>Ticker</th>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                        {Array(slider + 2).fill(() => {}).map((v, i) => {
                            return (
                            <tr key={i} className={i < slider ? styles.buyActive : ""}>
                            
                            <td>
                            {isAscending ? i + 1 : assetCount - i}
                            </td>
                            <td>
                                {isAscending ? "HIGH" : "LOW"}
                            </td>
                            <td style={{
                                color: "transparent",
                                textShadow: "#111 0 0 5px"
                            }}>
                            TIC
                            </td>
                            <td style={{
                                color: "transparent",
                                textShadow: "#111 0 0 5px"
                            }}>
                            Company Name
                            </td>
                        </tr>
                        )})}
                        </tbody>
                    </Table>
                </Col>
                <Col sm={3} >
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: "1.5rem",
                        fontWeight: "bold"
                    }}>
                        <span>Long</span>
                    </div>
                </Col>
            </Row>
        }
        {portfolioType === "LongShort"  &&
            <Row>
                <Col sm={9} >
                    <div className={styles.separator}>
                        <div>
                        <MdExpand size={"2rem"}/> {assetCount - (2 * slider + 4)} more rows collapsed
                        </div>
                    </div>
                </Col>
            </Row>
        }
        {portfolioType !== "LongOnly"  && 
            <Row>
                <Col sm={9}>
                <Table>
                    <thead>
                        <tr>
                            <th style={{
                                width: "4rem"
                                }}>
                                    Rank
                            </th>
                            <th style={{
                            width: "4rem"
                            }}>
                                Score
                            </th>
                            <th>Ticker</th>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array(slider + 2 )
                            .fill(() => {})
                            .map((v, i) => {
                                const adjustedAscending = isAscending;
                                return (
                                    <tr key={i} className={i < 2 ? "" : styles.sellActive}>
                                        <td>
                                            {adjustedAscending ? assetCount - (slider - (i - 1)) : slider - i + 2}
                                        </td>
                                        <td>
                                            {isAscending ? "LOW" : "HIGH"}
                                        </td>
                                        <td style={{
                                            color: "transparent",
                                            textShadow: "#111 0 0 5px"
                                        }}>
                                            TIC
                                        </td>
                                        <td style={{
                                            color: "transparent",
                                            textShadow: "#111 0 0 5px"
                                        }}>
                                            Company Name
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </Table>
                    </Col>
                    <Col sm={3} >
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: "1.5rem",
                            fontWeight: "bold"
                        }}>
                            <span>Short</span>
                        </div>
                    </Col>
                </Row>
            }
    </>
  )
}

export default RankingTable