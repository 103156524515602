import { useNavigate } from "react-router-dom";
import Card from "../../common/Card";
import styles from './ModelCard.module.scss';
import ReactECharts from 'echarts-for-react'; 
import { useModelsQuery } from "../../../hooks/useModel";
import { Placeholder } from "react-bootstrap";
import { padNumber, toFixed } from "../../../utils/utils";
import { FactorPerformanceReducedType, FactorPerformanceType } from "../../../api/schema";

const FactorCard = ({
    factors,
    strategy
}: {
    factors: FactorPerformanceReducedType
    strategy: string
}) => {
    
    const modelsQuery = useModelsQuery();
    const navigate = useNavigate();

    if (modelsQuery.data) {
        const series = factors[strategy].reduce((acc, item: any) => {
            const a = acc[item.factor] || [];
            return {
                ...acc,
                [item.factor]: [...a, [item.date, padNumber(toFixed((item.performance || 0) * 100, 2), 2)]]
            };
        }, {} as {[key: string]: any[]});

        const options = {
            legend: {},
            xAxis: {
                type: 'time', 
                axisLabel: {
                    formatter: function (value: string, index: number) {
                        var date = new Date(value);
                        return date.toLocaleDateString() ;
                    }
                }
              },
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: "{value}%",
                margin: 5
              },
              name: 'Cumulative Returns of Factors',
              nameLocation: 'middle',
              nameGap: 50
            },
            series: Object.keys(series).map((key) => {
                return {
                    name: key,
                    type: 'line',
                    triggerLineEvent: true,
                    data: series[key]
                };
            }),
            tooltip: {
                trigger: 'axis',
                formatter: function (param: any) {
                    return  (
                        `<div>Date: ${(param[0].value[0] as Date).toLocaleDateString()}</div>
                        ${param.map((p: any) => `<div style="display:flex; justify-content:space-between"><span style="margin-right:10px">${p.marker} ${p.seriesName}</span> <span>${p.value[1]}%</span></div>`).join("")}
                        `
                    );

                }
              },
          };
        return (
            <div className={styles.card} style={{
                cursor: "pointer"
            }}>
                <Card style={{
                    height: "inherit",
                    display: "flex",
                    flexDirection: "column",
                }} >
                    
                    <ReactECharts option={options} onEvents={{
                        click: async (params: any) => {
                            navigate(`/model?factor=${params.seriesName}&strategy=${strategy}`)
                        }
                    }}/>
                    
                </Card>
            </div>
        );
    }
  
    return (
        <div className={styles.card} style={{
            height: "100%",
            cursor: "pointer"
        }}>
            <Card style={{
                display: "flex",
                flexDirection: "column",
            }} >
                <Placeholder as="div" animation="glow" >
                    <Placeholder xs={12} style={{
                        height: "300px"
                    }}/>
                </Placeholder>     
            </Card>
        </div>
    )
}

export default FactorCard