import { Col, Row } from "react-bootstrap"
import BacktestSettingsForm from "./ModelSettingsForm";
import { PerformanceDetails } from "./PerformanceDetails";
import { ModelPerformanceChart } from "./ModelPerformanceChart";
import { DrawdownChart } from "./DrawdownChart";
import PortfolioConstituents from "./PortfolioConstituents";
import { RebalanceReturns } from "./RebalanceReturns";
import { useParams } from "react-router-dom";
import { useModelQuery } from "../../../hooks/useModel";

export const PortfolioDetail = () => {
  let { modelId = "" } = useParams();
  const modelQuery = useModelQuery(modelId || "");

    return (
      <Row>
          <h1 className="ms-3">{modelQuery.data ? modelQuery.data.name : ""}</h1>
          <Col md={8} sm={12}>
            <div className="px-3">
                <ModelPerformanceChart />
                <PortfolioConstituents />
                <DrawdownChart />
                <RebalanceReturns />
                <PerformanceDetails />
            </div>
          </Col>
          <Col md={4}>
              <BacktestSettingsForm/>
          </Col>
      </Row>
    ) 
}
