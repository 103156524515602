import { useFactorMomentumQuery } from "../../../hooks/useFactors";
import ReactECharts from 'echarts-for-react';
import { FactorStrategy } from "../../../api/schema";
import { toFixed } from "../../../utils/utils";

export const MomentumChart = ({
    factor
}: {
    factor: FactorStrategy
}) => {
    const momentumQuery = useFactorMomentumQuery(factor.userId, factor.PK, factor.Factor);
    
    if (momentumQuery.data) {
        if (momentumQuery.data.length === 0) {
            return (
                <>
                    <div>No data for this factor</div>
                </>
            );
        }

        const options = {
            xAxis: {
                type: 'time'
              },
            yAxis: {
              type: 'value',
              name: 'Performance',
              nameLocation: 'middle',
              nameGap: 50,
              axisLabel: {
                  formatter: "{value}%",
                  margin: 5
              }
            },
            series: ["Log_Return_Q1", "Log_Return_Q2", "Log_Return_Q3", "Log_Return_Q4", "Log_Return_Q5"].map((type) => {
                return {
                    data: momentumQuery.data?.map((point: any) => [point["Date"], point[type] * 100]),
                    type: 'line',
                    name: type,
                    showSymbol: false,
                    hoverAnimation: false
                }
            }),
            tooltip: {
                trigger: 'axis',
                formatter: function (param: any) {
                    const qunatileTextMap = {
                        "Log_Return_Q1": "Q1 (Low Score)",
                        "Log_Return_Q2": "Q2",
                        "Log_Return_Q3": "Q3",
                        "Log_Return_Q4": "Q4",
                        "Log_Return_Q5": "Q5 (High Score)"
                    };

                    return  (
                        `Date: ${new Date((param[0].value[0])).toLocaleDateString()} <br/>
                        ${param.map((p: any) => `<div style="display:flex; justify-content:space-between"><span style=margin-right:10px>${p.marker} ${qunatileTextMap[(p.seriesName as keyof typeof qunatileTextMap )]}</span> <span>${p.value[1] < 0 ? "" : "+" }${toFixed(p.value[1])}%</span></div>`).join("")}
                        `
                    );
                }
            },
          };

          return (
            <>
                <h4>Quantile Cumulative Returns</h4>
                <ReactECharts option={options} />
            </>
          );
    } 

    if(momentumQuery.error) {
        console.log(momentumQuery.error);
    }

    return (
        <>
            {/* <Form.Select value={selectedFactor} onChange={(e) => setSelectedFactor(e.target.value)}>
                {query.data?.map((factor) => <option value={factor.Factor}>{factor.Factor}</option>)}
            </Form.Select> */}
            {/* <FactorSelector factor={selectedFactor} onChange={setSelectedFactor} /> */}
            <div>Loading...</div>
        </>
    );
}
