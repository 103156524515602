import { OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { BacktestReportItem, DrawdownType, FactorModelType, drawdownSchema } from "../../../api/schema";
import { getUniverse, padNumber, toFixed } from "../../../utils/utils";
import { useModelQuery, useModelReportQuery } from "../../../hooks/useModel";
import { useParams } from "react-router-dom";
import { AiTwotoneInfoCircle } from "react-icons/ai";
import { useUniverseQuery } from "../../../hooks/useUniverse";

export const PerformanceDetails = () => {
    let { modelId = "" } = useParams() ;
    const modelQuery = useModelQuery(modelId);
    const universeQuery = useUniverseQuery(getUniverse(modelQuery.data?.strategyId) || "");
    const model = modelQuery.data;
    const report = useModelReportQuery(model?.userId, model?.modelId);
    const labelMap = {
        "Final-Equity": "Total Return",
        "CAGR": "Annual Returns (CAGR)",
        "Volatility": "Annual Volatility",
        "Sharpe": "Sharpe Ratio",
        "Max-Drawdown": "Max Drawdown",
        "Alpha-Universe": "Alpha (Universe)",
        "Beta-Universe": "Beta (Universe)",
        "Alpha-SP500": "Alpha (S&P 500)",
        "Beta-SP500": "Beta (S&P 500)",
        "Sterling": "Sterling Ratio",
        "Sortino": "Sortino Ratio",
        "Calmar": "Calmar Ratio",
        "Win-Rate": "Win Rate",
        "VaR95": "Value at Risk (95%)",
        "CVaR95": "Conditional Value at Risk (95%)",
        "Skewness": "Skewness",
        "Kurtosis": "Kurtosis"
    };

    const multiply100Fields = [
        "Final-Equity",
        "CAGR",
        "Volatility",
        "Max-Drawdown",
        "Alpha-Universe",
        "Alpha-SP500",
        "Win-Rate",
        "VaR95",
        "CVaR95"
    ];

    const percentageFields = [
        "Final-Equity",
        "CAGR",
        "Volatility",
        "Max-Drawdown",
        "Win-Rate",
        "VaR95",
        "CVaR95"
    ];

    const signFields = [
        "Final-Equity",
        "CAGR",
    ];
    const absValueFields = [
        "Volatility",
        "Sharpe",
        "Alpha-Universe",
        "Alpha-SP500",
        "Beta-Universe",
        "Beta-SP500",
        "Sterling",
        "Sortino",
        "Calmar",
        "Win-Rate",
    ];

    const tooltip = {
        "LongShort": "Performance of a hypothetical strategy with 50% Long and 50% Short weights. The ranking of stocks with the Lowest/Highest score is displayed in the Edit Strategy section.",
        "LongOnly": "Performance of stocks submitted as Long positions in the Edit Strategy section. Stocks with the Highest or Lowest score can be used.",
        "LongTheShort": "Performance of stocks submitted as Short positions in the Edit Strategy section. Stocks with the Lowest or Highest score can be used. For this calculation, stocks are longed, not shorted.",
        "SP100": "Performance of the Universe depending on the Edit Model section.",
        "SP500": "Performance of the Standard & Poor‘s 500 stock index.",
        "Final-Equity": "Expresses the percentage change in the value of the portfolio over the period considered. (+) expresses an increase in value, (-) expresses a decrease in value.",
        "CAGR": "Compounded Annual Growth rate (CAGR), annualized performance.",
        "Volatility": "The standard deviation of daily returns, multiplied by the number of trading days in the year for its annual adjustment.",
        "Sharpe": "The ratio of average excess returns (i.e. returns above the risk-free rate of return) to the standard deviation of returns, adjusted by an annual factor.",
        "Max-Drawdown": "The biggest value by which the portfolio has fallen over the review period.",
        "Alpha-Universe": "Measures the excess return relative to the volatility of the benchmark. An alpha greater than zero indicates better risk-adjusted performance.",
        "Beta-Universe": "Measures the sensitivity of the strategy to benchmark movements. A beta value of 1 means that the strategy moves in line with the benchmark. A beta greater than 1 indicates higher volatility than the benchmark.",
        "Alpha-SP500": "Measures the excess return relative to the volatility of the benchmark. An alpha greater than zero indicates better risk-adjusted performance.",
        "Beta-SP500": "Measures the sensitivity of the strategy to benchmark movements. A beta value of 1 means that the strategy moves in line with the benchmark. A beta greater than 1 indicates higher volatility than the benchmark.",
        "Calmar": "The ratio of the annual return (CAGR) to the absolute value of the maximum Max-Drawdown, i.e. the ratio between the expected return and the risk of the investment.",
        "Sterling": "The ratio between the expected return on investment and the average 10% negative drawdown.",
        "Sortino": "The ratio between the excess return of the portfolio over the risk-free rate and the downside deviation, which is the standard deviation of negative returns.",
        "Win-Rate": "The ratio of positive returns/rebalances to total returns/rebalances.",
        "VaR95": "Value at Risk calculates the 5th percentile, the value below which the lowest 5% of all observations of percentage price changes between rebalances fall.",
        "CVaR95": "The average value of losses that are worse than the 5th percentile.",
        "Skewness": "Expresses how asymmetrically the percentage price changes between rebalancing periods are distributed around the mean. The indicator shows whether they are more spread out on one side of the diameter than the other.",
        "Kurtosis": "Expresses how the percentage price changes between rebalancing periods (simple returns) are concentrated around the mean value. The indicator shows how flattened or spiky the data is."
    };

    if (report.data) {
        return (
            <Row>
                <h3>Performance Details</h3>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            {/* {
                                ["Metrics", "Strategy", "SP100", "SP500", "LongOnly", "ShortOnly", "LongTheShort"].map((option) => (
                                    <th key={option}>{columnsMap[option as keyof typeof columnsMap]}</th>
                                ))
                            } */}
                            <th rowSpan={2}>Metrics </th>
                            <th rowSpan={2}>
                                Strategy
                            </th>
                            <th rowSpan={2}>Universe&nbsp;{universeQuery.data && `(${universeQuery.data?.universe})`}
                                <OverlayTrigger 
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {tooltip.SP100}
                                        </Tooltip>
                                    }>
                                    <span>
                                        <AiTwotoneInfoCircle/>
                                    </span>
                                </OverlayTrigger>
                            </th>
                            <th rowSpan={2}>Benchmark (S&P&nbsp;500)
                                <OverlayTrigger 
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {tooltip.SP500}
                                        </Tooltip>
                                    }>
                                    <span>
                                        <AiTwotoneInfoCircle/>
                                    </span>
                                </OverlayTrigger>
                            </th>
                            <th colSpan={3} style={{
                                textAlign: "center"
                            }}>Automatic Strategy Decomposition (no&nbsp;leverage)</th>
                        </tr>
                        <tr>
                            <th>
                                Long Leg Performance
                                <OverlayTrigger 
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {tooltip.LongOnly}
                                        </Tooltip>
                                    }>
                                    <span>
                                        <AiTwotoneInfoCircle/>
                                    </span>
                                </OverlayTrigger>
                            </th>
                            <th>
                                Short Leg Performance
                                <OverlayTrigger 
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {tooltip.LongTheShort}
                                        </Tooltip>
                                    }>
                                    <span>
                                        <AiTwotoneInfoCircle/>
                                    </span>
                                </OverlayTrigger>
                            </th>
                            <th>
                                Long-Short Performance
                                <OverlayTrigger 
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {tooltip.LongShort}
                                        </Tooltip>
                                    }>
                                    <span>
                                        <AiTwotoneInfoCircle/>
                                    </span>
                                </OverlayTrigger>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ["Final-Equity", "CAGR", "Volatility", "Sharpe", "Max-Drawdown", "Alpha-Universe", "Beta-Universe", "Alpha-SP500",  "Beta-SP500", "Sterling", "Sortino", "Calmar", "Win-Rate", "VaR95", "CVaR95", "Skewness", "Kurtosis" ].map((metric) => {
                                const metricTest = metric as keyof BacktestReportItem;
                                return (
                                    <tr key={metricTest}>
                                        <td>
                                            {labelMap[metricTest]}
                                            {tooltip.hasOwnProperty(metricTest) && <OverlayTrigger

                                                placement="top"
                                                overlay={
                                                <Tooltip>
                                                    {tooltip[metricTest as keyof typeof tooltip]}
                                                </Tooltip>
                                            }>
                                                <span>
                                                    <AiTwotoneInfoCircle style={{
                                                        marginInlineStart: "5px",
                                                    cursor: "pointer"
                                                }}/>
                                                </span>
                                            </OverlayTrigger>}
                                        </td>

                                        {
                                            Object.keys(drawdownSchema.shape).map((option: string) => {
                                                const key = option as keyof DrawdownType;
                                                const value = report.data[key];
                                                let formatedValue = 0;
                                                
                                                if (value) {
                                                    const n = metricTest === "Final-Equity" ? value[metricTest] - 1 : value[metricTest];
                                                    const multipliedValue = multiply100Fields.includes(metricTest) ? n * 100 : n;
                                                    const absValue = absValueFields.includes(metricTest) ? Math.abs(multipliedValue) : multipliedValue;
                                                    formatedValue = toFixed(absValue);

                                                    return (
                                                        <OverlayTrigger 
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    {signFields.includes(metricTest) && formatedValue > 0 ? "+" : ""}{absValue}{percentageFields.includes(metricTest) ? "%" : ""}
                                                                </Tooltip>
                                                            }
                                                            key={key}>
                                                            <td key={key} style={{
                                                                textAlign: "right"
                                                                }}>
                                                                {signFields.includes(metricTest) && formatedValue > 0 ? "+" : ""}{padNumber(formatedValue, 2)}{percentageFields.includes(metricTest) ? "%" : ""}
                                                            </td>
                                                        </OverlayTrigger>
                                                    );
                                                } else {
                                                    return (
                                                        <td key={key} style={{
                                                            textAlign: "right"
                                                        }}></td>
                                                    );
                                                }
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
            </Row>  
        );
    }

    if (report.error) {
        return (
            <div>
                Error fetching backtest performance!
            </div>
        );
    }

    return (
        <Row>
            <h3>Performance Details</h3>
            <Table striped bordered hover responsive>
                <thead>
                        <tr>
                            {/* {
                                ["Metrics", "Strategy", "SP100", "SP500", "LongOnly", "ShortOnly", "LongTheShort"].map((option) => (
                                    <th key={option}>{columnsMap[option as keyof typeof columnsMap]}</th>
                                ))
                            } */}
                            <th rowSpan={2}>Metrics</th>
                            <th rowSpan={2}>Strategy</th>
                            <th rowSpan={2}>Universe</th>
                            <th rowSpan={2}>Benchmark (S&P 500)</th>
                            <th colSpan={3}>Automatic Strategy Decomposition (no leverage)</th>
                        </tr>
                        <tr>
                            <th>Long Leg Performance</th>
                            <th>Short Leg Performance</th>
                            <th>Long-Short Performance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ["Final-Equity", "CAGR", "Volatility", "Sharpe", "Max-Drawdown", "Alpha-Universe", "Beta-Universe", "Alpha-SP500",  "Beta-SP500", "Sterling", "Sortino", "Calmar", "Win-Rate", "VaR95", "CVaR95", "Skewness", "Kurtosis" ].map((metric) => {
                                const metricTest = metric as keyof BacktestReportItem;
                                return (
                                    <tr key={metricTest}>
                                        <td>{labelMap[metricTest]}</td>

                                        {
                                            Object.keys(drawdownSchema.shape).map((option: string) => {
                                                const key = option as keyof DrawdownType;
                                               
                                                return (
                                                    <td key={key}></td>
                                                );
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
            </Table>
        </Row>
    )   
}
