import { Table } from "react-bootstrap";
import { useFactorDetailsQuery, useFactorsListQuery } from "../../../hooks/useFactors";
import { useModelQuery } from "../../../hooks/useModel";
import { BsArrowUpRight, BsArrowDownRight } from "react-icons/bs";
import { padNumber, toFixed } from "../../../utils/utils";

const FactorInfo = ({
    modelId,
}: {
    modelId: string
}) => {
    const modelQuery = useModelQuery(modelId || "");
    const factorsQuery = useFactorsListQuery();
    const factorsDetailsQuery = useFactorDetailsQuery();
  
    if (modelQuery.data && factorsQuery.data && factorsDetailsQuery.data) {
        const factor = Object.keys(modelQuery.data.factor || {})[0];
        const strategy = factorsQuery.data.find((strategy) => strategy.PK === modelQuery.data.strategyId); 
        const factorMetrics = strategy?.factors.find((f) => f.Factor === factor);
        const factorDetail = factorsDetailsQuery.data.find((f) => f.Feature === factor);
        
        return (
            <>
                <div>
                    <h5>Stats</h5>
                    <Table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                                <td>Signal Strength</td>
                                <td>{padNumber(toFixed(factorMetrics?.["T-Stat"] || "0", 4), 4)}</td>
                            </tr>
                            <tr>
                                <td>Statistical confidence</td>
                                <td>{toFixed(factorMetrics?.["P-Value"] || 0 * 100, 4)}%</td>
                            </tr>
                            <tr>
                                <td>Signal direction</td>
                                <td>{factorMetrics?.Direction === 1 ? <BsArrowUpRight/> : <BsArrowDownRight/>}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div>
                    <h5>Factor Info</h5>
                    {factorDetail?.Description}
                </div>
            </>
        )    
    }
  
  
    return (
        <div>Loading...</div>
    );
}

export default FactorInfo