import ReactECharts from 'echarts-for-react'; 
import { Col, Form, Placeholder, Row } from 'react-bootstrap';
import DateRangeSelector, { DateRangeOptions, firstDateOfRange } from '../../common/DateRangeSelector';
import { useEffect, useState } from 'react';
import { BacktestChartKeys, BenchmarkType, FactorModelType } from '../../../api/schema';
import { useModelChartQuery, useModelQuery } from '../../../hooks/useModel';
import moment from 'moment';
import PortfolioIndicator from '../../common/PortfolioIndicator';
import ModelPerformance from '../../common/ModelPerformance';
import { useBenchmarksQuery } from '../../../hooks/useUniverse';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { updateModel } from '../../../api/api';
import { useMessages } from '../../../hooks/useMessages';
import { toFixed } from '../../../utils/utils';

const upperCaseProperties = [
  "sp100",
  "sp500"
];

export const ModelPerformanceChart = ({
}: {
}) => {
    let { modelId = "" } = useParams() ;
    const modelQuery = useModelQuery(modelId || "");
    const model = modelQuery.data;
    const query = useModelChartQuery(model?.userId, model?.modelId);
    const benchmarksQuery = useBenchmarksQuery();
    const backtestPeriod = moment(modelQuery.data?.backtest?.backtestPeriod);
    const [benchmark, setBenchmark] = useState<string>("sp500");
    const benchmarkName = (upperCaseProperties.includes(benchmark) ? benchmark.toUpperCase() : benchmark) as BacktestChartKeys;
    const {addInfoMessage} = useMessages();
    const changeModel = useMutation({
      mutationFn: async (newBenchmark: string) => {
        const backtest = model?.backtest && {
          ...model.backtest,
          benchmark: newBenchmark
        };

        if (model && updateModel) {
          await updateModel(model?.userId || "", modelId, {
            ...model,
            backtest
          });
        }
      },
      onSuccess: () => {
          addInfoMessage("Benchmark change", `Default benchmark for portfolio ${model?.strategyId} ${Object.keys(model?.factor|| {})[0]} has been changed`);
      }
  });

  useEffect(() => {
    if (modelQuery.data?.backtest?.benchmark) {
      setBenchmark(modelQuery.data.backtest.benchmark);
    }
  }, [modelQuery.data]);

  const onBenchmarkChange = (e: any) => {
    setBenchmark(e.target.value)
    changeModel.mutate(e.target.value);
  };


    if (modelQuery.data && query.data) {
      const timeseries = query.data.filter((point: any) => moment(point.date).isAfter(backtestPeriod));
      if (timeseries[0]) {
        const type = "Strategy" as BacktestChartKeys;
        const isBenchmark = benchmarkName in (timeseries[0] || {});
        const options = {
          responsive: true,
          maintainAspectRatio: false,
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'time'
          },
          yAxis: {
            type: 'value',
            boundaryGap: [0, '100%'],
            min: function (value :any) {
              return Math.round(value.min - 20);
            },
            max: function (value: any) {
              return Math.round(value.max + 20);
            },
            axisLabel: {
              formatter: "${value}",
              margin: 5
            },
            name: 'Final Equity',
            nameLocation: 'middle',
            nameGap: 80
          },
          
          series: [{
            data: timeseries.map((point: any) => [new Date(point.date), point[type]]),
            type: 'line',
            name: type,
            showSymbol: false,
            hoverAnimation: false,
          }, ...(isBenchmark ? [{
            data: timeseries.map((point: any) => [new Date(point.date), point[benchmarkName]]),
            type: 'line',
            name: benchmarksQuery.data ? Object.values(benchmarksQuery.data.results).find((b) => b.universe === benchmark)?.name : benchmark,
            showSymbol: false,
            hoverAnimation: false,
          }] : [])
          ],
          tooltip: {
            trigger: 'axis',                
            formatter: function (params: any) {
              const date = new Date(params[0].value[0]);
              const value = params[0].value[1];
              const secondLine = params[1] ? `<div style="display:flex; justify-content:space-between"><span style="margin-right:10px">${params[1].marker} ${benchmarkName}</span> <span>$${toFixed(params[1]?.value[1], 0)}</span></div>` : "";
              return `<div>Date: ${date.toLocaleDateString()}</div>
                <div style="display:flex; justify-content:space-between"><span style="margin-right:10px">${params[0].marker} ${params[0].seriesName}</span> <span>$${toFixed(value, 0)}<span></div>
                ${secondLine}
              `;
            }
          }
        };
  
  
  
        return (<div>
          <Row className="mb-2">
            <h3>Equity of Strategy and Benchmark</h3>
          </Row>
          <Row>
            <Col>
                <div className="mt-3">
                    <ModelPerformance userId={model?.userId || ""} modelId={model?.modelId} type={type} />
                </div>
            </Col>
            <Col>
              <div style={{
                display: "flex",
                justifyContent: "flex-end"
              }}>
                <Form.Select value={benchmark} onChange={(e) => onBenchmarkChange(e)}>
                  {Object.keys(benchmarksQuery.data?.results || {}).map((b) => {
                    const universe = benchmarksQuery.data?.results[b].universe;
                    const value = universe !== "fama" ? universe : b;
                    return (
                      <option key={b} value={value}>{benchmarksQuery.data?.results[b].name}</option>
                    );
                  })}
                </Form.Select>
              </div>
            </Col>
          </Row>
          <ReactECharts option={options} notMerge={true}/>
        </div>);
      }
    }

    if (query.isError) {
        return <div>Error fetching backtest chart!</div>;
    }

    return (
      <Placeholder as="div" animation="glow" >
          <Placeholder xs={12} style={{
            height: "300px"
          }}/>
      </Placeholder>
    );      
}
