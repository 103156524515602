import { Button, ButtonGroup, Carousel, Col, Row } from "react-bootstrap"
import Timeline from "../../common/Timeline"
import { FactorModelType, ReturnType } from "../../../api/schema";
import { useModelCurrentReturnsQuery, useModelQuery, useModelReturnsQuery } from "../../../hooks/useModel";
import { useCallback, useMemo, useState } from "react";
import ReportPieChart from "./ReportPieChart";
import { useParams } from "react-router-dom";
import { fetchScore } from "../../../api/api";

const getLatestDate = (dates: string[]) => {
    const lastDate = dates.map(key => new Date(key)).reduce((a, b) => a > b ? a : b, new Date(0));
    return `${lastDate.getFullYear()}-${(lastDate.getMonth() + 1).toString().padStart(2, "0")}-${lastDate.getDate().toString().padStart(2, "0")}`;
};

const PortfolioConstituents = () => {
    let { modelId = "" } = useParams() ;
    const modelQuery = useModelQuery(modelId);
    const model = modelQuery.data;
    const returnsQuery = useModelReturnsQuery(model?.userId, model?.modelId);
    const currentReturnsQuery = useModelCurrentReturnsQuery(model?.userId, model?.modelId);
    
    const [selectedRebalance, setSelectedRebalance] = useState<string>("");
    const [displayType, setDisplayType] = useState<"Equity" | "Percent">("Percent");
    
    
    const mergedReturns = useMemo(() => {
        if (!returnsQuery.data || !currentReturnsQuery.data) {
            return {};
        }

        const rebalanceToDelete = getLatestDate(Object.keys(returnsQuery.data || {}));
        const secondRebalanceToDelete = getLatestDate(Object.keys(returnsQuery.data || {}).filter(d => d !== rebalanceToDelete));

        const data = {
            ...returnsQuery.data
        };

        delete data[rebalanceToDelete || ""];
        delete data[secondRebalanceToDelete || ""];

        const merge = {
        ...data,
        ...currentReturnsQuery.data
        };

        
        return merge;
    }, [returnsQuery.data, currentReturnsQuery.data]);
    
    const rebalanceDates = useMemo(() => (Object.keys(mergedReturns || {})), [mergedReturns]);
    const onDownloadPress = useCallback(async (clickedDate: string) => {
        try {
          const d = mergedReturns[clickedDate]?.Rebalance_Day || "";
          const res =await fetchScore(model?.userId || "", model?.modelId || "", d) as string[];
          const s = res.join("\n");
          const BOM = "\uFEFF";
          const data = new Blob([BOM + s], { type: "text/csv;charset=utf-8" });
          const csvURL = window.URL.createObjectURL(data);
          const tempLink = document.createElement("a");
          tempLink.href = csvURL;
          tempLink.setAttribute('download', `Score_${d}.csv`);
          tempLink.click();
        } catch (error) {
          console.log(error);
        }
      }, [model?.userId, model?.modelId, selectedRebalance, rebalanceDates]);
    const longMap = {
        "Equity": "LongPortfolioUSD",
        "Percent": "LongPortfolioShares"
    };
    const shortMap = {
        "Equity": "ShortPortfolioUSD",
        "Percent": "ShortPortfolioShares"
    };

    if (returnsQuery.data && currentReturnsQuery.data) {
        if (Object.keys(mergedReturns).length > 0) {
            const date = selectedRebalance || getLatestDate(rebalanceDates);
            const longProperty = longMap[displayType] as keyof ReturnType;
            const shortProperty = shortMap[displayType] as keyof ReturnType;
            

            const shorts = mergedReturns[date][shortProperty]|| {};
            const longs = mergedReturns[date][longProperty]|| {};
            const shortsSum = typeof shorts === "object" ? Object.keys(shorts).reduce((acc, ticker) => acc + shorts[ticker], 0) : 0;
            const longsSum = typeof longs === "object" ? Object.keys(longs).reduce((acc, ticker) => acc + longs[ticker], 0) : 0;
            const lastUpdate = currentReturnsQuery.data[Object.keys(currentReturnsQuery.data)[0]]?.Updated;

            return (
                <>
                    <Row className="mt-3 mb-1">
                        <h3>Portfolio constituents and real-time performance tracking</h3>
                    </Row>
                    {/* <Row>
                        <Carousel data-bs-theme="dark" activeIndex={rebalanceDates.length -1 } indicators={true}>
                            {rebalanceDates.map((date) => (
                            <Carousel.Item>
                                    <Row>
                                        {date}
                                    </Row>
                                    <Row>
                                        <ButtonGroup aria-label="Basic example">
                                            <Button 
                                                variant={displayType === "Equity" ? "primary" : "secondary" }
                                                onClick={() => setDisplayType("Equity")}>
                                                Equity
                                            </Button>
                                            <Button variant={displayType === "Percent" ? "primary" : "secondary" } onClick={() => setDisplayType("Percent")}>Percent</Button>
                                        </ButtonGroup>
                                    </Row>
                                    <Row>
                                        <Col sm={shortsSum !== 0 ? 6 : 12}>
                                            <ReportPieChart title="Long" shares={returnsQuery.data[date]?.[longProperty]} type={displayType} />
                                        </Col>
                                        {shortsSum !== 0 && <Col sm={6}>
                                            <ReportPieChart title="Short" revert shares={returnsQuery.data[date]?.[shortProperty]} type={displayType} />                
                                        </Col>}
                                    </Row>
                                </Carousel.Item>   
                            ))}
                        </Carousel>
                    </Row> */}
                    <Row>
                        <Timeline key="data" onDownloadPress={onDownloadPress} dates={rebalanceDates} performance={rebalanceDates.map(d => mergedReturns[d].Strategy)} lastUpdate={lastUpdate} selectedDate={selectedRebalance || rebalanceDates[rebalanceDates.length - 1]} onDateChange={setSelectedRebalance} />
                    </Row>
                    <Row className="mx-2">
                        {/* <Row>
                            <ButtonGroup aria-label="Basic example">
                                <Button 
                                    variant={displayType === "Equity" ? "primary" : "secondary" }
                                    onClick={() => setDisplayType("Equity")}>
                                    Equity
                                </Button>
                                <Button variant={displayType === "Percent" ? "primary" : "secondary" } onClick={() => setDisplayType("Percent")}>Percent</Button>
                            </ButtonGroup>
                        </Row> */}
                        <Row>
                            {longsSum !== 0 && <Col xl={12} xxl={longsSum !== 0 ? 6 : 12}>
                                <ReportPieChart gain={mergedReturns[date]?.Longs} center={mergedReturns[date]?.LongLeg} title={`Long ${model?.backtest?.ascending ? "(Stocks with lowest score)" : "(Stocks with highest score)"}`} shares={mergedReturns[date]?.[longProperty]} type={displayType} />
                            </Col>}
                            {shortsSum !== 0 && <Col xl={12} xxl={longsSum !== 0 ? 6 : 12}>
                                <ReportPieChart  gain={mergedReturns[date]?.Shorts} center={mergedReturns[date]?.ShortLeg} title={`Short ${model?.backtest?.ascending ? "(Stocks with highest score)" : "(Stocks with lowest score)"}`} revert shares={mergedReturns[date]?.[shortProperty]} type={displayType} />                
                            </Col>}
                        </Row>
                    </Row>
                </>
            )
        }
    }

    return (
        <>
        <Row className="mt-3 mb-1">
            <h3>Portfolio constituents and real-time performance tracking</h3>
        </Row>
        <Row>
            <Timeline onDownloadPress={onDownloadPress} key="loading" dates={[]} performance={[]} selectedDate="" onDateChange={setSelectedRebalance} />
        </Row>
        <Row className="mx-2">
            <Row>
                <Col sm={6}>
                    <ReportPieChart center={0} title="Long"  type={displayType}/>
                </Col>
                <Col sm={6}>
                    <ReportPieChart center={0} title="Short" revert type={displayType}/>
                </Col>
            </Row>
        </Row>
    </>
    )
  
}

export default PortfolioConstituents