import { Button, ButtonGroup, Col, Form, InputGroup, Modal, OverlayTrigger, Popover, Row, Spinner, Tooltip } from "react-bootstrap"
import Card from "../../../../common/Card"
import Filter from "../../../../common/Filter"
import {  useCallback, useMemo, useState } from "react";
import { FilterOperator } from "../../../../common/Filter/Filter";
import FactorsTable from "../../FactorsTable";
import { useFactorDetailsQuery, useZoomedFactorsListQuery } from "../../../../../hooks/useFactors";
import { FactorStrategy } from "../../../../../api/schema";
import { CumulativeReturnChart } from "../../CumulativeReturnChart";
import styles from './Modal.module.scss';
import { useBenchmarksQuery } from "../../../../../hooks/useUniverse";
import 'react-calendar/dist/Calendar.css';
import DateRangeInput from "../../../../common/DateRangeInput";
import useFactorMimickingFilters from "./useFactorMimickingFilters";
import useFactorMimickingTable from "./useFactorMimickingTable";


const FactorMimickingDialogTimeframe = ({
    show,
    onDialogClose
}: {
    show: boolean;
    onDialogClose: (factor?: FactorStrategy) => void;
}) => {
    const [shownFactors, setShownFactors] = useState<FactorStrategy[]>([]);
    const {
        search,
        signalDirection,
        universe,
        tStat,
        tStatOperator,
        pValue,
        pValueOperator,
        buckets,
        selectedBucket,
        selectedFactor,
        zoomDateRange,
        filterBarFilters,
        setSearch,
        setSignalDirection,
        setUniverse,
        setTStat,
        setTStatOperator,
        setPValue,
        setPValueOperator,
        setSelectedBucket,
        setSelectedFactor,
        setZoomDateRange,
        resetFilters
    } = useFactorMimickingFilters();

    const {
        isDirty,
        isLoading,
        strategyId,
        filteredFactors,
        onGoClick,
        resetTable
    } = useFactorMimickingTable(filterBarFilters);
  
    
    const isConfirmationDisabled = !selectedFactor;
    const benchmarksQuery = useBenchmarksQuery();

    const onBeforeDialogClose = () => {
        const factor = selectedFactor ? {
            ...selectedFactor,
            poolName: universe,
            PK: `admin_${universe}_M`,
            userId: "admin"
        } as FactorStrategy : undefined;
        
        setSelectedFactor(null);
        resetFilters();
        resetTable();
        onDialogClose(factor);
    };


  return (
    <Modal show={show} onHide={onBeforeDialogClose} dialogClassName={styles.largeModal}>
        <Modal.Header closeButton>
            <Modal.Title>Overwrite existing</Modal.Title>
        </Modal.Header>
        <Row className="m-2">
            <div className="mb-3">
                <Card>
                    <Form>
                        <Row className="mb-2">
                            <Col>
                                <Form.Label htmlFor="inputPassword5">Universe</Form.Label>
                                <Form.Select aria-label="Default select example" value={universe} onChange={(e) => setUniverse(e.target.value)}>
                                    {benchmarksQuery.data && Object.keys(benchmarksQuery.data.results).filter(b => benchmarksQuery.data.results[b].type === "benchmarks").map((b) => (
                                        <option key={benchmarksQuery.data.results[b].universe} value={benchmarksQuery.data.results[b].universe}>{benchmarksQuery.data.results[b].name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col className="ms-auto pe-0">
                                <Form.Group className="mb-3" controlId="capital">
                                    <Form.Label>Zoom (Date Range)</Form.Label>
                                    <DateRangeInput dateRange={zoomDateRange} onDateRangeChange={setZoomDateRange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-2">

                            <Col>
                                <Form.Label htmlFor="inputPassword5">Factor</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />    
                            </Col>
                            <Col>
                                <Form.Label htmlFor="inputPassword5">Group</Form.Label>
                                <Form.Select aria-label="Default select example" value={selectedBucket} onChange={(e) => setSelectedBucket(e.currentTarget.value)}>
                                    <option value=""> </option>
                                    {Array.from(buckets || []).map((bucket) => (
                                        <option key={bucket} value={bucket}>{bucket}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label htmlFor="inputPassword5">Statistical Confidence</Form.Label>
                                <Row>
                                    <Filter operator={pValueOperator} value={pValue} step={1} min={0} max={100} suffix="%" onChange={(filter) => {
                                        setPValue(filter.value); 
                                        setPValueOperator(filter.operator);
                                    }}/>
                                </Row>
                            </Col>
                            <Col>
                                <Form.Label htmlFor="inputPassword5">Signal Strength</Form.Label>
                                <Row>
                                    <Filter operator={tStatOperator} value={tStat} onChange={(filter) => {
                                        setTStat(filter.value); 
                                        setTStatOperator(filter.operator);
                                    }}/>
                                </Row>
                            </Col>

                            <Col>
                                <Form.Label htmlFor="inputPassword5">Signal Direction</Form.Label>
                                <Row>
                                <Form.Select aria-label="Default select example" value={signalDirection} onChange={(e) => setSignalDirection(e.currentTarget.value)}>
                                    <option key="" value=""> </option>
                                    <option key="signal" value="positive">Positive Returns</option>
                                    <option key="negative" value="negative">Inverse Signals</option>
                                </Form.Select>
                                    {/* <ButtonGroup aria-label="Basic example">
                                        <Button variant={signalDirection === "positive" ? "primary" : "secondary"} onClick={() => setSignalDirection("signal")}>Positive Returns</Button>
                                        <Button variant={signalDirection === "negative" ? "primary" : "secondary"} onClick={() => setSignalDirection("negative")}>Inverse Signals</Button>
                                    </ButtonGroup> */}
                                </Row>
                            </Col>
                        </Row>
                        <Row className="justify-content-end">
                            <Col lg={2} className="pe-0">
                                <Button className="w-100" variant="primary"  onClick={(e) => onGoClick(e)}>
                                    Search
                                </Button>
                            </Col>
                            <Col lg={2} className="pe-0">
                                <Button className="w-100" variant="secondary" onClick={() => {resetFilters(); resetTable()}}>
                                    Reset
                                </Button>
                            </Col>
                            
                        </Row>
                    </Form>
                </Card>
            </div>
            <div style={{
                position: "relative",
            }}>
                <div className="mb-3">
                    <CumulativeReturnChart userId="admin" strategyId={strategyId} factors={shownFactors} onFactorClick={(factorName: string) => {
                        const factor = filteredFactors.find((f: FactorStrategy) => f.Factor === factorName);
                        if (factor) {
                            setSelectedFactor(factor);
                        }
                    }}/> 
                </div>
                
                <FactorsTable factors={filteredFactors} selectedRow={selectedFactor} onShownFactorsChange={setShownFactors} setSelectedRow={setSelectedFactor}/>
                {isDirty && <div style={{
                        backdropFilter: "blur(5px)",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        zIndex: 100,
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0
                }}>
                    {isLoading && <div style={{
                        "display": "flex",
                        "height": "100%",
                        "justifyContent": "center",
                        "alignItems": "center"
                    }}>
                        <Spinner animation="border" role="status" variant="primary">
                            <span className="visually-hidden">Data is preparing...</span>
                        </Spinner>
                    </div>}
                </div>}
            </div>
        </Row>
        <Modal.Footer>
            <Button variant="secondary" onClick={onBeforeDialogClose}>
                Cancel
            </Button>
            <Button variant="primary" disabled={isConfirmationDisabled} type="submit" onClick={onBeforeDialogClose}>
                Choose Factor
            </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default FactorMimickingDialogTimeframe