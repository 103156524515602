import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FactorModelType } from "../../../../api/schema";
import { useModelsQuery } from "../../../../hooks/useModel";

const ChooseModelDialog = ({
    show,
    handleClose
}: {
    show: boolean,
    handleClose: (selectedModel?: FactorModelType) => void,
}) => {
    const modelsQuery = useModelsQuery();
    const [selectedModel, setSelectedModel] = useState<string>("");

    useEffect(() => {
        if (modelsQuery.data) {
            setSelectedModel(modelsQuery.data[0]?.modelId || "");
        }
    }, [modelsQuery.data, setSelectedModel])

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Overwrite existing</Modal.Title>
            </Modal.Header>
            <div className="m-2">
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Overwrite my portfolio:</Form.Label>    
                <Form.Select aria-label="Model to overwrite" onChange={(e) => setSelectedModel(e.target.value)}>
                    {(modelsQuery.data || []).map((model) => (
                        <option key={model.modelId} value={model.modelId} >{model.name}</option>
                    ))}
                </Form.Select>
            </Form.Group>
            </div>
            <Modal.Footer>
            <Button variant="secondary" onClick={() => handleClose()}>
                Cancel
            </Button>
            <Button variant="primary" type="submit" onClick={(e) => {
                e.preventDefault();
                const choosenModel = modelsQuery.data?.find((model) => model.modelId === selectedModel);
                handleClose(choosenModel);
            }}>
                Overwrite model
            </Button>
            </Modal.Footer>
        </Modal>
      )
}

export default ChooseModelDialog