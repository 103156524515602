import Button from "react-bootstrap/Button";
import { CreateBacktestForm, FactorModelType } from "../../../api/schema";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useModelQuery } from "../../../hooks/useModel";
import { getUniverse, isLowestScore } from "../../../utils/utils";
import { useUniverseQuery } from "../../../hooks/useUniverse";


const BacktestSettingsForm = ({
    handleClose,
    onSubmit
}: {
    handleClose?: () => void;
    onSubmit?: (backtest: CreateBacktestForm) => Promise<void>;
}) => {
    let { modelId = "" } = useParams() ;
    const modelQuery = useModelQuery(modelId);
    const universeQuery = useUniverseQuery(getUniverse(modelQuery.data?.strategyId) || "");
    const navigate = useNavigate();
    const today = new Date();
    const formatDate = (d?: string) => {
        return d ? new Date(d).toLocaleDateString() : today.toLocaleDateString();
    };
    const onEditPres = (path: string) => {
        navigate(path);
    };

        if (modelQuery.data) {
            const model = modelQuery.data;
            return (
                <>
                    <Row><h3>Strategy and backtest parameters</h3></Row>
                    <Row>
                        <div style={{
                            textAlign: "left",
                        }}>
                            <div>Built on model: <span className="metrics">{Object.keys(model.factor|| {})[0]}</span> </div>
                            <div>The portfolio consist of the top <span className="metrics">{model.backtest?.numberOfStocks}</span> assets ranked by the model.</div>
                            {/* <div><span className="metrics">{model.backtest?.longOnly ? "Use" : "Don't use"}</span> the bottom 10 assets from the rank as a portfolio hedge.</div> */}
                            <div>The strategy is using leverage <span className="metrics">{model.backtest?.leverage}</span> for the invested capital.</div>
                            <div><span className="metrics">{model.backtest?.longsShare}</span> of the capital is invested uniformly as long positions in the stocks with the {model.backtest?.ascending ? "lowest" : "highest"} score.</div>
                            <div><span className="metrics">{model.backtest?.shortsShare}</span> of the capital is invested uniformly as short positions in the stocks with the {model.backtest?.ascending ? "highest" : "lowest"} score.</div>
                            <div><span className="metrics">0%</span> of the capital is left as cash reserve.</div>
                        </div>
                    </Row>
                    <Row className="mt-4">
                        <h3>Backtesting</h3>
                    </Row>
                    <Row>
                    <div style={{
                            textAlign: "left",
                        }}>
                            <div>Multi-period out-of-sample walk-forward method. This method provides the greatest integrity and predictability of results.</div>
                            <div>The results are approximate and do not take into account real market behavior such as fees, slippages and other market effects.</div>
                            <div>Performance is calculated with historical end-of-day price data.</div>
                            <div>Dividends and earnings are included here in the cumulative performance as if they were continuously reinvested. This differs from the Adjust Model section where returns are not reinvested.</div>
                            {universeQuery.data && <div>Universe represents the current composition of the sample <span className="metrics">{universeQuery.data?.universe}</span> index. Benchmarks are calculated based on their historical composition.</div>}
                            <div>The backtest period begins at: <span className="metrics">{formatDate(model.backtest?.backtestPeriod)}</span> and ends at <span className="metrics">{formatDate(model.backtest?.backtestPeriodEnd)}</span></div>
                            <div>Capital of <span className="metrics">${model.backtest?.startCash}</span> is  initially invested into the strategy.</div>
                        </div>
                    </Row>
                    <Row className="mt-4">
                        <Col md={6}>
                            <Button 
                                style={{
                                    width: "100%"
                                }}
                                onClick={() => onEditPres(`/model/${model.modelId}`)}>
                                Edit Model
                            </Button>
                        </Col>
                        <Col md={6}>
                            <Button
                                style={{
                                    width: "100%"
                                
                                }}
                                onClick={() => onEditPres(`/strategy/${model.modelId}`)}>
                                Edit Strategy
                            </Button>
                        </Col>
                    </Row>
                </>
            )
        }

        return (
            <>
                <Row><h3>Strategy and backtest parameters</h3></Row>
            </>
        )
}

export default BacktestSettingsForm;