import { useQueryClient } from "react-query";
import { useAuth } from "../hooks/useAuth";
import { useMessages } from "../hooks/useMessages";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useCallback, useState } from "react";
import { getUserDetails } from "../api/analitycalPlatformApi";
const { useEffect, createContext, useRef } = require("react");


const WebSocketContext = createContext()

function WebSocketProvider({ children }: { children: React.ReactNode }) {
    const { user, accessToken } = useAuth();
    const queryClient = useQueryClient();
    const {addInfoMessage, addErrorMessage} = useMessages();
    const [socketUrl, setSocketUrl] = useState(`${process.env.REACT_APP_WS_ENDPOINT}?token=${accessToken}`);

    useEffect(() => {
        setSocketUrl(`${process.env.REACT_APP_WS_ENDPOINT}?token=${accessToken}`);
    }, [accessToken]);
    
    const { lastMessage } = useWebSocket(socketUrl, {
        onOpen: () => addInfoMessage("New connection", "Connection to real time data has been established"),
        onClose: () => console.log('WS close'),
        onError: () => getUserDetails(),

        retryOnError: true,
        reconnectAttempts: 10,
        reconnectInterval: 3000,
    });


    useEffect(() => {
        if (lastMessage !== null) {
            const { type, ...data } = JSON.parse(lastMessage.data)
                        if (type === "REFRESH") {
                            const target = data.target.split("/");
                            queryClient.invalidateQueries({ queryKey: target });
                        } else if (type === "MESSAGE") {
                            switch (data.severity) {
                                case "info":
                                    addInfoMessage(data.title, data.message);    
                                    break;
                                case "error":
                                    addErrorMessage(data.title, data.message);    
                                    break;
                            }
                        }
        }
      }, [lastMessage, queryClient, addErrorMessage, addInfoMessage]);


    /* WS provider dom */
    /* subscribe and unsubscribe are the only required prop for the context */
    return (
        <WebSocketContext.Provider value={[]}>
            {children}
        </WebSocketContext.Provider>
    )
}

export { WebSocketContext, WebSocketProvider }