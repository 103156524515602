import TreeView, { flattenTree } from "react-accessible-treeview";
import { FaSquare, FaCheckSquare, FaMinusSquare } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";
import { useFactorDetailsQuery, useFactorsListQuery } from "../../../hooks/useFactors";
import "./FactorTree.css";
import { FactorDetailsType, FactorStrategy } from "../../../api/schema";
import { Form, FormControl } from "react-bootstrap";
import { useMemo } from "react";
import { useBenchmarksQuery } from "../../../hooks/useUniverse";


const FactorTree = ({
    onChange,
    selectedRow,
    search,
    filterValue,
    setSearch
}: {
    onChange: (factor: FactorStrategy) => void;
    selectedRow?: FactorStrategy;
    search: string;
    filterValue: string;
    setSearch: (s: string) => void;
}) => {
    const factorsQuery = useFactorsListQuery();
    const factorsDetailsQuery = useFactorDetailsQuery();
    const benchmarksQuery = useBenchmarksQuery();
    // const [selectedIds, setSelectedIds] = useState<string[]>([]);
    // const [expandedIds, setExpandedIds] = useState<string[]>([]);

    const buckets = useMemo(() => {
        return factorsDetailsQuery.data ? [...factorsDetailsQuery.data.reduce((acc, f) => {
            acc.add(f.Bucket);
            return acc;
        }, new Set<string>())] : [];
    }, [factorsDetailsQuery.data]);

    const factorsInBucket = useMemo(() => {
        return factorsDetailsQuery.data?.reduce((acc, f) => {
            if (!acc.has(f.Bucket)) {
                acc.set(f.Bucket, []);
            }
            acc.get(f.Bucket)?.push(f.Feature);
            return acc;
        }, new Map<string, string[]>());
    }, [factorsDetailsQuery.data]);

    const bucketMap = useMemo(() => {
        const factorDetailsMap = factorsDetailsQuery.data?.reduce((acc, f) => {
            acc.set(f.Feature, f);
            return acc;
        }, new Map<string, FactorDetailsType>());

        return factorsQuery.data && factorDetailsMap ? factorsQuery.data.reduce((acc, factor) => {
            factor.factors.forEach((f) => {
                const {factors, ...rest} = factor;
                const extendedFactor = {
                    ...f,
                    ...rest
                };
                acc.set(`${factor.PK}  ${f.Factor}`, extendedFactor);

                return acc;
            });

            return acc;
        }, new Map<string, FactorStrategy>()) : new Map<string, FactorStrategy>();
    }, [factorsQuery.data, factorsDetailsQuery.data]);


    const applyFilter = (f: any) => {
        return (!filterValue || f.Factor.toLowerCase().includes(filterValue.toLowerCase()));
    };

    const factors = (factorsQuery.data || []).map((factor, i) => {
        const b = buckets.map((bucket: string) => {
            const a =  factorsInBucket?.get(bucket) || [];
            return {
                id: `${factor.PK} ${bucket}`,
                name: bucket as string,
                children: [...a].filter((f) => {
                    const test= bucketMap.get(`${factor.PK}  ${f}`);
                    return test?.["P-Value"] && test["T-Stat"] && applyFilter(test);
                }).map((f) => {
                    const test= bucketMap.get(`${factor.PK}  ${f}`);
                    return {
                        id: `${test?.PK}  ${test?.Factor}`,
                        name: test?.Factor || "",
                    }
                })
            }
        }).filter((b) => b.children.length > 0); 

        return {
            id: `${factor.PK}`,
            name: benchmarksQuery.data ? Object.values(benchmarksQuery.data.results).find((b) => b.universe === factor.poolName)?.name || factor.poolName : factor.poolName,
            children: b
        };
    });

    // const selectedIds = useMemo(() => {
    //     if (!selectedRow) {
    //         return [];
    //     }
    // }, [selectedRow]);

    // const expandedIds = useMemo(() => {
    //     if (!selectedRow || !factorsDetailsQuery.data) {
    //         return [];
    //     } 
    //     const parentTree = factors.find(f => f.id === selectedRow.PK);
    //     if (parentTree) {
    //         const test = parentTree.children.reduce((acc: string[], b) => {
    //             const f = b.children.map((c) => c.name);
    //             return [...acc, ...f];
    //         }, []).some((f) => f === selectedRow.Factor);
    //         if (!test) {
    //             return [];
    //         }
    //     }

    //     const a =factorsDetailsQuery.data?.find((f) => f.Feature === selectedRow.Factor);
    //     if (a) {
    //         return [`${selectedRow.PK}`, `${selectedRow.PK} ${a?.Bucket}`];
    //     }
    // }, [selectedRow, factorsDetailsQuery.data, factors]);


    // useEffect(() => {
    //     if (selectedRow && factorsDetailsQuery.data) {
    //         const a =factorsDetailsQuery.data?.find((f) => f.Feature === selectedRow.Factor);
    //         setSelectedIds([`${selectedRow.PK}  ${selectedRow.Factor}`]);
    //         if (a) {
    //             setExpandedIds([`${selectedRow.PK}`, `${selectedRow.PK} ${a?.Bucket}`]);
    //         }
    //     }
    // }, [selectedRow, factorsDetailsQuery.data, selectedIds])



    
    const data = flattenTree({
        name: "",
        children: factors
    });
    return (
        <>
            <Form className="my-2">
                <FormControl placeholder="Search for indicator" value={search} onChange={(e) => {
                    setSearch(e.target.value);
                    e.preventDefault();
                }}/>
            </Form>
            <div style={{
                height: "83vh",
                overflow: "auto"    
            }}>
            {!factors.some(f => f.children.length !== 0)  ? 
                <div>None of the factors meet the selection criteria</div> :       
                    <TreeView
                        data={data}
                        className="basic"
                        multiSelect={false}
                        aria-label="basic example tree"
                        nodeRenderer={({ 
                            element,
                            isBranch,
                            isExpanded,
                            isSelected,
                            getNodeProps,
                            level,
                            handleSelect,
                            handleExpand,
                        }) => (
                            <div
                                {...getNodeProps()}
                                style={{ marginLeft: 20 * (level - 1) }}
                                onClick={(e) => {
                                    if (!isBranch) {
                                        const node = bucketMap.get(element.id.toString()) || {} as FactorStrategy;
                                        console.log(node.PK);
                                        onChange(node);
                                        handleSelect(e);
                                    } else {
                                        handleExpand(e);
                                    }
                                }}
                            >
                            {isBranch ?
                                <>
                                    <ArrowIcon isOpen={isExpanded} />
                                    <span className="name">{element.name}</span>
                                </> : <>
                                    <span className={`name ${isSelected ? "active" : ""}`}>{element.name}</span>
                                </>
                                }
                        </div>
                        )}
                    />}
            </div>
        </>
    )
}

const ArrowIcon = ({isOpen}: {
    isOpen: boolean;
}) => {
    const baseClass = `arrow--${isOpen ? "open" : "closed"}`;
    return <IoMdArrowDropright className={baseClass} />;
  };

  const CheckBoxIcon = ({ variant }: {
    variant: "all" | "none" | "some";
  }) => {
    switch (variant) {
      case "all":
        return <FaCheckSquare/>;
      case "none":
        return <FaSquare />;
      case "some":
        return <FaMinusSquare />;
      default:
        return null;
    }
  };

export default FactorTree