import { getANPHost } from "../utils/envHandler";
import { deleteCookie, getCookie } from "../utils/utils";
import { isTokenValid, refreshAuthToken } from "./fetch";

let isTokenRefreshed = false;
let waitUntilRefresh = Promise.resolve();

export const getUserDetails = async () => {
  const isValidToken = isTokenValid();

  if (!isValidToken && !isTokenRefreshed) {
    let res = () => {};
    isTokenRefreshed = true;
    waitUntilRefresh = new Promise((resolve) => {
      res = resolve;
    });
    await refreshAuthToken();
    isTokenRefreshed = false;
    res();
  } else {
    await waitUntilRefresh;
  }
    try {
      const response = await fetch(`${getANPHost()}/rest-auth/user/`, {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${getCookie('accessToken')}`
        }
      });
    
      if (!response.ok) {
        throw new Error("Error fetching user details");
      }
      
      const data = await response.json();
      return data;
    } catch (e) {
      deleteCookie("accessToken");
      window.location.replace(getANPHost());
    }
  };
