import { useEffect, useMemo, useState } from "react";
import { FilterOperator } from "../../../../common/Filter/Filter";
import { FactorStrategy } from "../../../../../api/schema";
import { useFactorDetailsQuery } from "../../../../../hooks/useFactors";

export type FilterBarFiltersType = {
    search: string,
    signalDirection: string,
    universe: string,
    tStat: number|undefined,
    tStatOperator: FilterOperator,
    pValue: number|undefined,
    pValueOperator: FilterOperator,
    selectedBucket: string,
    zoomDateRange?: [Date, Date]
};

const useFactorMimickingFilters = () => {
    const [search, setSearch] = useState("");
    const [signalDirection, setSignalDirection] = useState<string>("");
    const [universe, setUniverse] = useState("sp100");
    const [tStat, setTStat] = useState<number| "">();
    const [tStatOperator, setTStatOperator] = useState<FilterOperator>(">");
    const [pValue, setPValue] = useState<number| "">("");
    const [pValueOperator, setPValueOperator] = useState<FilterOperator>(">");
    const [selectedBucket, setSelectedBucket] = useState<string>("");
    const [selectedFactor, setSelectedFactor] = useState<FactorStrategy|null>(null);
    const [zoomDateRange, setZoomDateRange] = useState<[Date, Date]|undefined>();
    const factorsDetailsQuery = useFactorDetailsQuery();

    const resetFilters = () => {
        setSearch("");
        setSignalDirection("");
        setUniverse("sp100");
        setTStat(undefined);
        setTStatOperator(">");
        setPValue("");
        setPValueOperator(">");
        setSelectedBucket("");
        setSelectedFactor(null);
        setZoomDateRange(undefined);
    };

    
    const buckets = useMemo(() => {
        return (factorsDetailsQuery.data || []).reduce((acc, val) => {
            acc.add(val.Bucket);
            return acc;
        }, new Set<string>());
    }, [factorsDetailsQuery.data]);

    const filterBarFilters = useMemo(() => {
        return {
            search,
            signalDirection,
            universe,
            tStat,
            tStatOperator,
            pValue,
            pValueOperator,
            selectedBucket,
            zoomDateRange
        };
    }, [search, signalDirection, universe, tStat, tStatOperator, pValue, pValueOperator, selectedBucket, zoomDateRange]);


    return {
        search,
        signalDirection,
        universe,
        tStat,
        tStatOperator,
        pValue,
        pValueOperator,
        buckets,
        selectedBucket,
        selectedFactor,
        zoomDateRange,
        filterBarFilters,
        setSearch,
        setSignalDirection,
        setUniverse,
        setTStat,
        setTStatOperator,
        setPValue,
        setPValueOperator,
        setSelectedBucket,
        setSelectedFactor,
        setZoomDateRange,
        resetFilters
    };
};

export default useFactorMimickingFilters;