import { PortfolioConstructionType } from "../api/schema";

export const toFixed = (num: number|string, precision: number = 2) => {
  const number = typeof num === 'string' ? parseFloat(num) : num;
  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
}

export const padNumber = (num: number, size: number) => {
  const [int, dec] = num.toString().split(".");
  return `${int}.${dec ? dec.padEnd(size, "0") : "0".repeat(size)}`;
}

export const getCookie =  (name: string) => {
  let cookie = {} as any;
  document.cookie.split(';').forEach(function(el: string) {
    let split = el.split('=');
    cookie[split[0].trim()] = split.slice(1).join("=");
  })
  return cookie[name];
};

export const setCookie = (name: string, value: string, days?: number) => {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

export const deleteCookie = (name: string) => {
  // Delete cookie from the current domain and path
  document.cookie = name + '=; Max-Age=-99999999; path=/';

  // Optionally, try to delete the cookie from parent or subdomains
  const domainParts = window.location.hostname.split('.');
  
  // Attempt to delete the cookie for the domain and its subdomains
  for (let i = 0; i < domainParts.length; i++) {
      let domain = domainParts.slice(i).join('.');
      document.cookie = name + `=; Max-Age=-99999999; domain=.${domain}; path=/`;
  }
}

export const isLowestScore = (isAscending?: boolean, constructionType?: PortfolioConstructionType) => {
  if ((typeof isAscending == "boolean") && constructionType) {
    if (isAscending) {
      return constructionType !== "ShortOnly"
    } else {
      return constructionType === "ShortOnly"
    }
  }
};

export const getUniverse = (strategyId?: string) => {
  return strategyId && strategyId.split("_")[1];
}

export const getBaseChartOptions = () => {
  return {

  };
};