import { ApiError, get, post, patch, del } from 'aws-amplify/api';
import { deleteCookie, getCookie, setCookie } from '../utils/utils';
import { jwtDecode } from "jwt-decode";
import moment from 'moment';
import axios from 'axios';
import { getANPHost } from '../utils/envHandler';

let isTokenRefreshed = false;
let waitUntilRefresh = Promise.resolve();

export const isTokenValid = () => {
  const token = getCookie('accessToken');
  const decodedToken = jwtDecode(token);
  if (decodedToken && decodedToken.exp) {
    const expirationTime = moment(decodedToken.exp * 1000);
    return moment().isBefore(expirationTime);
  } else {
    return false;
  }
}

export const refreshAuthToken = async () => {
  const host = getANPHost();  
  try{
      const refreshToken = getCookie('refreshToken');
      console.log(host);
      const response = await axios.post(`${host}/api/token/refresh/`, {
        refresh: refreshToken
      });

      const {access, refresh} = response.data;
      setCookie("accessToken", access);
      setCookie("refreshToken", refresh);
    } catch {
      deleteCookie("accessToken");
      deleteCookie("refreshToken");

      window.location.href = host;
    } finally {
    }
};
  
const getCall = async (apiName: string, path: string) => {
  
  const callGet = () => get({
    apiName,
    path,
    options: {
      headers: {
          'Authorization': `Bearer ${getCookie('accessToken')}`
      }
    }
  });
  const isValidToken = isTokenValid();

  if (!isValidToken && !isTokenRefreshed) {
    let res = () => {};
    isTokenRefreshed = true;
    waitUntilRefresh = new Promise((resolve) => {
      res = resolve;
    });
    await refreshAuthToken();
    isTokenRefreshed = false;
    res();
  } else {
    await waitUntilRefresh;
  }

    const request = callGet();
    return await request.response;
};

const postCall = async (apiName: string, path: string, body: any) => {
  const isValidToken = isTokenValid();

  if (!isValidToken && !isTokenRefreshed) {
    let res = () => {};
    isTokenRefreshed = true;
    waitUntilRefresh = new Promise((resolve) => {
      res = resolve;
    });
    await refreshAuthToken();
    isTokenRefreshed = false;
    res();
  } else {
    await waitUntilRefresh;
  }
  const request = post({
    apiName,
    path,
    options: {
      headers: {
        'Authorization': `Bearer ${getCookie('accessToken')}`
      },
      body: body
    }
  });
  return request.response;
};

const patchCall = async (apiName: string, path: string, body: any) => {
  const isValidToken = isTokenValid();

  if (!isValidToken && !isTokenRefreshed) {
    let res = () => {};
    isTokenRefreshed = true;
    waitUntilRefresh = new Promise((resolve) => {
      res = resolve;
    });
    await refreshAuthToken();
    isTokenRefreshed = false;
    res();
  } else {
    await waitUntilRefresh;
  }
  const request = patch({
    apiName,
    path,
    options: {
      headers: {
        'Authorization': `Bearer ${getCookie('accessToken')}`
      },
      body: body
    }
  });
  return await request.response;
}

const deleteCall = async (apiName: string, path: string) => {
  const isValidToken = isTokenValid();

  if (!isValidToken && !isTokenRefreshed) {
    let res = () => {};
    isTokenRefreshed = true;
    waitUntilRefresh = new Promise((resolve) => {
      res = resolve;
    });
    await refreshAuthToken();
    isTokenRefreshed = false;
    res();
  } else {
    await waitUntilRefresh;
  }
  const request = del({
    apiName,
    path,
    options: {
      headers: {
        'Authorization': `Bearer ${getCookie('accessToken')}`
      }
    }
  });
  return await request.response;
}

export const apiGetCall = async (apiName: string, path: string) => {
    try {
      let data
      const response = await getCall(apiName, path);
      data = await response.body.json();
      return data;
    } catch (error: any) {
      throw error;
    }
  };

  export const apiPostCall = async (apiName: string, path: string, body: any) => {
    try {
      let data

      const response = await postCall(apiName, path, body);
      data = await response.body.json();
      return data;
    } catch (error: any) {
      throw error;
    }
  };

  export const apiPatchCall = async (apiName: string, path: string, body: any) => {
    let data
    try {
      const response = await patchCall(apiName, path, body);
      data = await response.body.json();
      
      return data;
    } catch (error: any) {
      throw error;
    }
  };

  export const apiDeleteCall = async (apiName: string, path: string) => {
    try {
      return await deleteCall(apiName, path);
    } catch (error: any) {
      throw error;
    }
  };